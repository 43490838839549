import React from "react";
import { Flex } from "@chakra-ui/react";
import { ServiceCard } from "../ServiceCard";

type Props = {
  content: {
    img: string;
    title: string;
    description: string;
    onSubmit(): void;
  }[];
};

export const HorizontalScrolling: React.FC<Props> = (props) => {
  const { content } = props;

  return (
    <Flex overflowX="auto" w="100%" p="4">
      {content.map((item, index) => (
        <ServiceCard
          key={index}
          title={item.title}
          description={item.description}
          img={item.img}
          onSubmit={item.onSubmit}
        />
      ))}
    </Flex>
  );
};
