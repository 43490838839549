import { Box, Button, Card, Image, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  img: string;
  title: string;
  description: string;
  onSubmit(): void;
};

export const ServiceCard: React.FC<Props> = (props) => {
  const { img, title, description, onSubmit } = props;
  return (
    <Card
      height={"auto"}
      flexShrink={0}
      width="55%"
      borderRadius="lg"
      overflow="hidden"
      m="2"
    >
      <Box as="div">
        <Image borderRadius="xl" objectFit="cover" src={img} padding={"8px"} />
        <Box padding={"8px"}>
          <Text fontSize={"xs"} textAlign={"center"} paddingBottom={"8px"}>
            {title}
          </Text>
          <Button
            width={"100%"}
            size={"xs"}
            colorScheme={"orange"}
            onClick={onSubmit}
          >
            {description}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
