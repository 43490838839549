import { Box, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  OutdoorSection,
  SearchSection,
  ServiceSection,
} from "../../../components/HomeSections";

const Home: React.FC = () => {
  const [search, setSearch] = useState("");

  console.log(search);
  return (
    <Box paddingTop={22}>
      <Box display="flex" justifyContent="right" paddingX={"16px"}>
        <Button colorScheme="orange" size={"sm"}>
          Seja um profissional
        </Button>
      </Box>
      <OutdoorSection />
      <SearchSection onChange={setSearch} />
      <ServiceSection />
    </Box>
  );
};

export default Home;
