import { Card, Text } from "@chakra-ui/react";
import React from "react";
import { HorizontalScrolling } from "../../HorizontalScrolling";

export const ServiceSection: React.FC = () => {
  return (
    <Card
      variant="filled"
      width={"100vd"}
      height={"auto"}
      paddingY={"16px"}
      paddingX={"16px"}
      backgroundColor={"orange.50"}
      borderRadius={0}
    >
      <Text fontSize={"xl"} textAlign={"center"}>
        Principais serviços oferecidos
      </Text>
      <Text fontSize={"xs"} textAlign={"center"} paddingTop={"8px"}>
        Confira os principais serviços dos profissionais cadastrados na nossa
        plataforma.
      </Text>
      <HorizontalScrolling
        content={[
          {
            title: "Limpeza / Faxina",
            description: "Buscar",
            img: "https://cdn.pixabay.com/photo/2017/09/29/15/47/cleanliness-2799496_960_720.jpg",
            onSubmit: () => {},
          },
          {
            title: "Frete / Mudança",
            description: "Buscar",
            img: "https://cdn.pixabay.com/photo/2016/09/02/00/06/container-1638068_960_720.jpg",
            onSubmit: () => {},
          },
          {
            title: "Cuidador(a)",
            description: "Buscar",
            img: "https://cdn.pixabay.com/photo/2015/10/10/19/57/elderly-981400_960_720.jpg",
            onSubmit: () => {},
          },
          {
            title: "Encanador",
            description: "Buscar",
            img: "https://cdn.pixabay.com/photo/2015/06/27/16/37/fire-823623_960_720.jpg",
            onSubmit: () => {},
          },
        ]}
      />
    </Card>
  );
};
