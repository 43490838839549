import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/store";

type Props = {
  children: React.ReactNode;
};
const ProtectedRoute: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const isLogged = useAppSelector((store) => store.user.isLogged);

  useEffect(() => {
    if (!isLogged) {
      return navigate("/");
    }
  }, [isLogged, navigate]);

  return <Fragment>{isLogged ? props.children : null}</Fragment>;
};

export default ProtectedRoute;
