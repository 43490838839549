import { createBrowserRouter } from "react-router-dom";
import PreHome from "./PreHome";
import DevAccess from "./Dev/DevAccess";
import Home from "./Dev/Home";
import ProtectedRoute from "../utils/ProtectedRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PreHome />,
  },
  {
    path: "/dev",
    element: <DevAccess />,
  },
  {
    path: "/dev/home",
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },
]);
